import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Row from "../row"
import TickSvg from "../../../assets/images/svg/white-tick.svg"

type CheckMarkProps = {
  selected: boolean
  hover: boolean
  className?: string
}

const CheckMark: React.FC<CheckMarkProps> = ({
  selected,
  hover,
  className,
}) => {
  return (
    <Row
      isCentered={true}
      className={`w-5 h-5 min-w-5 min-h-5 rounded  ${
        selected
          ? "bg-cuimc-button-blue-70 text-white"
          : `trans-ani border-2 border-solid border-gray-200 bg-white ${
              hover ? "border-cuimc-button-blue-70" : ""
            }`
      } ${className}`}
    >
      {selected && (
        <div>
          {/* <FontAwesomeIcon icon="check" className={`text-sm`} /> */}
          <img src={TickSvg} className="w-3" alt="Check mark" />
        </div>
      )}
    </Row>
  )
}

CheckMark.defaultProps = {
  className: "",
}

export default CheckMark
